.rhap_container {
    outline: none;
    background-color: rgb(198, 198, 198);
    border-radius: 10px;
    width: 92%;
    margin: 8px;
    height: 90px;;
}
.rhap_container, .rhap_container *:focus {
    outline: none;
}

.rhap_time {
    color: black;
}

.rhap_progress-bar-show-download {
    background-color: rgba(12, 11, 11, 0.5);
  }

  .rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    top: -8px;
    background: transparent;
    border-radius: 50px;
    box-shadow: rgba(98, 98, 98, 0.5) 0 0 5px;
  }

  .rhap_progress-bar-show-download {
    background-color: transparent;
  }

  .rhap_current-time {
    background-color: rgb(69, 69, 69);
    color: white;
    border-radius: 5px;
    padding: 3px;
    position: absolute;
    font-size: 15px;
    
  }