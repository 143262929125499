.banner-animated {
    
    
       
        position: absolute;
        animation: move 6s infinite;
      }
      
      
      @keyframes move {
        0% {top: 0%;}
        50% {top: 790px;}
        100% { top: 0%;}
        
      }
      

 .fondo{
  background-image: linear-gradient(
        90deg,
        rgb(32, 32, 32),
        rgba(32, 32, 32, 0.5)
        ),
        url("https://www.zonacaral.gob.pe/wp-content/uploads/2018/03/ruta-caral-panoramica.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

 }     